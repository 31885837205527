export class ModuleConstants {

  // Flips the keys and values of an object ([k, v] => [v, k]).
  static flip = obj => Object.fromEntries(Object.entries(obj).map(a => a.reverse()));
  // Returns true if val is either an integer or a string containing an integer; false, otherwise.
  public static isStringInteger = val => parseInt(val, 10) === +val;  // This works because NaN !== NaN.

  public static SUPPORTED_LOCALE = ['en', 'nl'];
  public static server = {
    'url': window.location.protocol + '//' + window.location.hostname,
    'port': window.location.port
  };
  public static emailRegex = "[A-Za-z0-9._%+\\-]+@[a-z0-9.\\-]+\\.[a-z]{2,3}$";
  public static events = {
    'states': {
      'alarm': 'ALARM',
      'ok': 'OK',
      'open': 'OPEN',
      'undefined': 'UNDEFINED'
    },
    'substates': {
      'unacked': 'UNACKED',
      'acked': 'ACKED'
    }
  };
  public static userPrivileges = {
    'general': 0,
    'addUser': 1,
    'addSite': 2,
    'addGraph': 3,
    'addCustomGraph': 4,
    'exportData': 5,
    'viewProjectContacts': 6,
    'viewDataConnections': 7,
    'viewMetrics': 8,
    'viewProjectUsers': 9,
    'editUserSubscriptions': 10,
    'viewRuleType': 11,
    'viewOverviews': 12,
    'viewUsers': 13,
    'editHandleInsufficientDataQuality': 14,
    'accessTechnicalDocumentation': 15,
    'editEquipmentStructures': 16,
  };
  public static appTypes = {
    'events': 'EVENTS',
    'equipmentstructure': 'EQUIPMENTSTRUCTURE',
    'exportdata': 'EXPORTDATA',
    'dashboard': 'DASHBOARD',
    'help': 'HELP',
    'historian': 'HISTORIAN',
    'samples': 'SAMPLES',
    'analytics': 'ANALYTICS',
    'users': 'USERS',
    'springboard': 'SPRINGBOARD',
    'projects': 'PROJECTS',
    'reports': 'REPORTS',
    'userprofile': 'PROFILES',
    'documents': 'DOCUMENTS',
    'connections': 'CONNECTIONS'
  };
  public static appSubTypes = {
    'view': 'view',
    'detail': 'detail',
    'equipments': 'equipments',
    'landingPage': 'landingPage',
    'config': 'config'
  };
  public static widgetTypes = {
    'table': 'TABLE',
    'historianchart': 'HISTORIANCHART',
    'equipmentchart': 'EQUIPMENTCHART',
    'rulechart': 'RULECHART',
    'custom': 'CUSTOM',
    'gauge': 'GAUGE',
    'dateTimeRange': 'DATE_TIME_RANGE',
    'contactInfo': 'CONTACT_INFO',
    'organisationInfo': 'ORGANISATION_INFO',
    'scheduler': 'SCHEDULER',
    'profile': 'PROFILE'
  };
  public static widgetSubTypes = {
    'actual': 'ACTUAL',
    'archive': 'ARCHIVE',
    'occurrences': 'OCCURRENCES',
    'kpi': 'KPI',
    'user': 'USER',
    'organization': 'ORGANIZATION',
    'site': 'SITE',
    'equipment': 'EQUIPMENT',
    'rule': 'RULE'
  };
  public static entityTypes = {
    'user': 'USER',
    'role': 'ROLE',
    'site': 'SITE',
    'organization': 'ORGANIZATION',
    'userProfile': 'USER_PROFILE',
    'equipment': 'EQUIPMENT',
    'pointType': 'POINT_TYPE'
  };
  public static dataPointTypes = {
    'configPoint': 'CONFIG_POINTS',
    'linkedPoint': 'LINKED_POINTS'
  };
  public static gaugeStatuses = {
    'warning': 'WARNING',
    'ok': 'OK',
    'alarm': 'ALARM',
    'undefined': 'UNDEFINED'
  };
  public static gaugeDataTypes = {
    'rule': 'RULE'
  };
  public static barStatuses = {
    'warning': 'WARNING',
    'ok': 'OK',
    'alarm': 'ALARM',
    'undefined': 'UNDEFINED'
  };
  public static chartTypes = {
    'line': 0,
    'pie': 1,
    'xy': 2,
    'heatmap': 3,
    toString(i: number) {
      return ModuleConstants.flip(ModuleConstants.chartTypes)[i];
    }
  };
  public static chartLines = {
    'line': 1,
    'column': 2,
    'scatter': 3,
    'spline': 4,
    'area': 5,
    'pie': 6,
    'bar': 7,
    toString(i: number) {
      return ModuleConstants.flip(ModuleConstants.chartLines)[i];
    }
  };
  public static chartColors = [
    '#FF0000',
    '#008000',
    '#4CFF00',
    '#FF00DC',
    '#00FFFF',
    '#0026FF',
    '#FF7FED',
    '#000000',
    '#FF6A00',
    '#C0C0C0',
    '#FFD800',
    '#FF7F7F',
    '#0094FF',
    '#4800FF',
    '#FF006E',
    '#FFB27F'
  ];
  static AVG_POINT_FUNCTION_ID = 0;
  static MIN_POINT_FUNCTION_ID = 1;
  static MAX_POINT_FUNCTION_ID = 2;
  static SUM_POINT_FUNCTION_ID = 3;
  // This array is purposely not public; it is accessed through the getter functions that follow.
  static pointFunctionList = [
    {id: ModuleConstants.AVG_POINT_FUNCTION_ID, name: 'avg', description: 'Gemiddelde'},
    {id: ModuleConstants.MIN_POINT_FUNCTION_ID, name: 'min', description: 'Minimum'},
    {id: ModuleConstants.MAX_POINT_FUNCTION_ID, name: 'max', description: 'Maximum'},
    {id: ModuleConstants.SUM_POINT_FUNCTION_ID, name: 'sum', description: 'Som'},
  ];
  static get pointFunctions() {
    let result = [];
    ModuleConstants.pointFunctionList.forEach(ob => result.push((({ id, description }) => ({ id, description }))(ob)));
    return result;
  }
  static get subPointFunctions() {
    let result: any = {};
    ModuleConstants.pointFunctionList.forEach(ob => result[ ob.description ] = ob.id.toString());
    return result;
  }
  public static functionsAsList = [
    {id: 0, description: 'Geen'},
    {id: 1, description: 'Gewogen gemiddelde (som 1 * 2) / som 2'},
    {id: 2, description: 'Som (1 + 2)'},
    {id: 3, description: 'Som (1 + 2 + 3)'},
    {id: 4, description: 'Som (1 + 2 + 3 + 4)'},
    {id: 5, description: 'Deling (1 / 2)'},
    {id: 6, description: 'Vermenigvuldiging (1 * 2)'},
    {id: 7, description: 'Min (1 - 2)'},
    {id: 8, description: 'Min (1 - 2 - 3)'},
    {id: 9, description: 'Min (1 - 2 - 3 - 4)'}
  ];
  public static grainTypes = {
    RAW: 99,
    HOUR: 0,
    DAY: 1,
    WEEK: 2,
    MONTH : 3,
    MONTHS2: 4,
    MONTHS3: 5,
    MONTHS6: 6,
    YEAR: 7,
    YEARS5: 8
  };
  public static chartWidgetSubTypes = {
    'normal': 'NORMAL',
    'heatMap': 'HEAT_MAP',
    'benchmark': 'BENCHMARK'
  };
  public static higchartTypes = {
    'heatmap': 'heatmap'
  };
  public static STYLES = [
    { style: 'enbytez', domains: ['portal.enbytez.nl'] },
    { style: 'bright-building', domains: ['portal.bright-building.nl'] }
  ];
  public static RULE_ICONS = {
    ENERGY: 'fa-bolt',
    COMFORT: 'fa-child',
    INSTALLATION: 'fa-cogs',
    PROCESS: 'fa-code-fork',
    DATA: 'fa-database'
  }
  public static BadgeClass = {
    SUCCESS: 'bg-success',
    MINOR_WARNING: 'bg-secondary',
    WARNING: 'bg-warning',
    ERROR: 'bg-danger'
  }

  public static HIGHCHARTS_COLORS = [
    '#009FE3', '#E6007E', '#7C7C7B', '#C6C6C6', // DWA colours
     "#F32735", "#FFC844", "#61A60E", "#8A1A9B" // other colours from the PowerBI styleguide
  ];
}
