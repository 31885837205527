import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { filter, pairwise} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class RoutingState {
    private history = [];

    constructor(
        private router: Router
    ) { }

    public loadRouting(): void {
        this.router.events
       //     .pipe(map(e => e.filter(er => er instanceof RoutesRecognized)))
            .pipe(filter(e => e instanceof RoutesRecognized))
            .pipe(pairwise())
            .subscribe((event: any[]) => {
                this.history.push(event[0].urlAfterRedirects);
                if (this.history.length > 3) { this.history = this.history.slice(1, this.history.length); }

            });
    }

    public getHistory(): string[] {
        return this.history;
    }

    public getPreviousUrl(i: number): string {
        return this.history[this.history.length - i] || '/';
    }

}
