import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './app-routing-guard';
import { AuthenticationService } from './services/authentication.service';
// import { BROWSER_DEALERS_FAVICONS_CONFIG } from './services/favicons';
// import { BrowserFavicons } from './services/favicons';
// import { Favicons } from './services/favicons';
const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/layout.module').then(m => m.LayoutModule),
   // loadChildren: './modules/layout.module#LayoutModule',
    canActivate: [AuthGuard]
  },
  { path: 'login', loadChildren: './login/login.module#LoginModule' },
  { path: 'reset-password', loadChildren: './reset-password/reset-password.module#ResetPasswordModule' },
  { path: 'forgot-password', loadChildren: './forgot-password/forgot-password.module#ForgotPasswordModule' },
  { path: 'twoFactorAuthentication', loadChildren: './two-factor-authentication/two-factor-authentication.module#TwoFactorAuthenticationModule' },
  { path: 'not-found', loadChildren: './not-found/not-found.module#NotFoundModule' },
  { path: '**', redirectTo: 'not-found' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    AuthGuard,
    AuthenticationService
  ]
/*  providers: [
    {
      provide: Favicons,
      useClass: BrowserFavicons
    },
    {
      provide: BROWSER_DEALERS_FAVICONS_CONFIG,
      useValue: {
        icons: {
          "Bright-Building": {
            type: "image/png",
            href: "./assets/favicon.ico",
            isDefault: true
          },
          "lift": {
            type: "image/png",
            href: "./assets/favicon.ico",
            isDefault: true
          }
        },
        // I determine whether or not a random token is auto-appended to the HREF
        // values whenever an icon is injected into the document.
        cacheBusting: false
      }
    }
  ],*/
//  exports: [RouterModule]
})
export class AppRoutingModule { }
