import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from './services/language.service';
import { RoutingState } from './services/routing-state.service';
import { HTTPStatus } from './services/interceptor/http.status';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

    true = true;
    HTTPActivity: boolean;

    constructor(translate: TranslateService,
        private languageService: LanguageService,
        private titleService: Title,
        routingState: RoutingState,
        private httpStatus: HTTPStatus) {
        translate.addLangs(['en', 'nl']);
        translate.setDefaultLang('nl');
        const browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|nl/) ? browserLang : 'nl');

        routingState.loadRouting();
        this.HTTPActivity = false;
        setTimeout(() => {
            this.httpStatus.getHttpStatus().subscribe((status: boolean) => {
                this.HTTPActivity = status;
            });
        }, 100);
    }

    ngOnInit() {
        this.languageService.setUserLocale();
        this.applyBrand('dwa');
    }

    applyBrand(style: string) {
        const dom: any = document.querySelector('body');
        dom.classList.toggle(style);
        this.setTitle("Bright Building");
    }

    setTitle(newTitle: string) {
        this.titleService.setTitle(newTitle);
    }
}
